import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  overflow: hidden;
  ${(props) => props.column && 'flex-direction: column'};
`;

class Slider extends React.Component {
  constructor() {
    super();
    this.state = { currentShowViews: [] };
  }

  componentDidMount() {
    this.initState(this.props);
  }

  componentWillReceiveProps(newProps) {
    if ((newProps.isMobile !== this.props.isMobile) || !newProps.preserveState) {
      this.initState(newProps);
    }
  }

  getCurrentShowViews() {
    return this.state.currentShowViews;
  }

  setCurrentShowViews(newShowViews) {
    if (newShowViews.length === this.state.currentShowViews.length) {
      this.setState({ currentShowViews: newShowViews });
    }
  }

  initState(props) {
    const currentShowViews = [];
    let index = 0;
    while (index < props.viewsToShow) {
      currentShowViews.push(index);
      index += 1;
    }
    this.setState({ currentShowViews });
  }

  prevIndex(index) {
    const { children, viewsToMove } = this.props;
    return ((index - viewsToMove) + children.length) % children.length;
  }

  nextIndex(index) {
    const { children, viewsToMove } = this.props;
    return (index + viewsToMove + children.length) % children.length;
  }

  prev() {
    const { children, viewsToMove, infinite } = this.props;
    if (this.state.currentShowViews[0] >= 1 || infinite) {
      const currentShowViews = [];
      let newIndex = 0;
      this.state.currentShowViews.forEach((index) => {
        newIndex = index - viewsToMove;
        if (newIndex < 0) newIndex += children.length;
        currentShowViews.push(newIndex);
      });
      this.setState({ currentShowViews });
    }
  }

  next() {
    const {
      children, viewsToShow, viewsToMove, infinite,
    } = this.props;
    if (this.state.currentShowViews[viewsToShow - 1] < (children.length - viewsToMove) || infinite) {
      const currentShowViews = [];
      this.state.currentShowViews.forEach((index) => {
        currentShowViews.push((index + viewsToMove) % children.length);
      });
      this.setState({ currentShowViews });
    }
  }

  render() {
    return (
      <Container column={this.props.column} style={this.props.sliderStyle}>
        {this.state.currentShowViews.map((index) => this.props.children[index])}
      </Container>
    );
  }
}

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  viewsToShow: PropTypes.number,
  viewsToMove: PropTypes.number,
  infinite: PropTypes.bool,
  column: PropTypes.bool,
  preserveState: PropTypes.bool,
  isMobile: PropTypes.bool,
  sliderStyle: PropTypes.shape({}),
};

Slider.defaultProps = {
  viewsToShow: 5,
  viewsToMove: 1,
  infinite: false,
  column: false,
  preserveState: false,
  isMobile: true,
  sliderStyle: {},
};

export default Slider;
