import React from 'react';
import styled from 'styled-components';

import {
  colors, margins, fontSizes, mobileThresholdPixels, Title2, Title3, ContainerBlock, Button,
}
  from '../home/v2/StyledComponents';
import Dots from '../home/v2/Dots';
import isMobile from '../../services/isMobile';
import photo from '../../assets/beTilliste/theWhat.png';
import photoMobile from '../../assets/beTilliste/theWhat-mobile.png';

const Texts = [
  {
    id: 0,
    paragraph: !isMobile()
      ? (
        <span>
          Être un couturier de talent capable de
          <br />
          s'adapter à ses clients : mise à taille et
          <br />
          transformation.
        </span>
      )
      : (
        <span>
          Être un couturier de talent capable de s'adapter à
          <br />
          ses clients : mise à taille et transformation
        </span>
      ),
  },
  {
    id: 1,
    paragraph: !isMobile()
      ? (
        <span>
          Être diplômé dans le secteur de la
          <br />
          couture ou avoir au moins 5 ans
          <br />
          d'expérience et avoir un smartphone
          <br />
          (qui fonctionne).
        </span>
      )
      : (
        <span>
          Être diplômé dans le secteur de la couture ou
          <br />
          avoir au moins 5 ans d'expérience et avoir un
          <br />
          smartphone (qui fonctionne).
        </span>
      ),
  },
  {
    id: 2,
    paragraph: !isMobile()
      ? (
        <span>
          Être un travailleur indépendant. Si
          <br />
          vous ne l'êtes pas encore, nous vous
          <br />
          accompagnons dans les démarches.
        </span>
      )
      : (
        <span>
          Être un travailleur indépendant. Si vous ne l'êtes
          <br />
          pas encore, nous vous accompagnons dans les
          <br />
          démarches.
        </span>
      ),
  },
];

const InvertedBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${margins.l} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    padding: 2px 0px;
  }
`;

const Column = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90%;
  }
`;

const PositionedTitle3 = styled(Title3)`
  margin: 0px 0px ${margins.l} -${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px ${margins.m} 0px;
  }
`;

const P = styled.p`
  color: ${colors.navy};
  font-family: Roboto;
  font-size: ${fontSizes.xl};
  margin: 0px 0px ${margins.l} ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px ${margins.s} 0px;
    text-align: center;
    font-size: ${fontSizes.s};
  }
`;

const Img = styled.img`
  object-fit: cover;
  width: 100%;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} 0px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${margins.m};
  display: flex;
  justify-content: center;
`;

const TheWhat = () => (
  <ContainerBlock id="TheWhat">
    <Dots big />
    <Title2 big>Devenir couturier</Title2>
    <InvertedBlock>
      <Column><Img src={isMobile() ? photoMobile : photo} alt="Une tilliste tenant une paire de ciseaux" /></Column>
      <Column>
        <PositionedTitle3 big yellow>Être tilliste</PositionedTitle3>
        {Texts.map((content) => <P key={content.id}>{content.paragraph}</P>)}
      </Column>
    </InvertedBlock>
    <ButtonContainer>
      <Button>Découvrir nos Tillistes</Button>
    </ButtonContainer>
  </ContainerBlock>
);

export default TheWhat;
