import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { trackEvent } from '../../services/analytics';
import {
  colors, mobileThresholdPixels, margins, ContainerBlock, Button, Title2,
} from '../home/v2/StyledComponents';
import Block from '../home/Block';
import Dots from '../home/v2/Dots';
import heart from '../../assets/heart.svg';
import schedule from '../../assets/schedule.svg';
import euro from '../../assets/euro.svg';
import smile from '../../assets/smile.svg';
import group from '../../assets/group.svg';
import shape from '../../assets/shape.svg';

const InvertedBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: ${margins.xl};
  text-align: left;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 2px 0px;
  }
`;

const P = styled.p`
  color: ${colors.navy};
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: ${margins.s};
    text-align: center;
    font-size: 12px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${margins.xl};
`;

function trackClick() {
  trackEvent('click', 'devenir-couturier-formulaire_le-pourquoi');
}

const TheWhy = () => (
  <ContainerBlock id="TheWhy">
    <Dots big />
    <Title2 big>Pourquoi ?</Title2>
    <InvertedBlock>
      <Block title="Flexibilité" icon={schedule}>
        <P>En fonction de votre emploi du temps, Tilli vous confie des missions près de chez vous.</P>
      </Block>
      <Block title="Zéro risque" icon={shape} inverted>
        <P>
          {`Nous mettons en place une assurance responsabilité civile professionnelle qui vous permettra de travailler
          en toute sécurité.`}
          <br />
          <Link to="/assurance-AXA-tilliste">En savoir plus</Link>
        </P>
      </Block>
      <Block title="Simplicité" icon={smile}>
        <P>
          {`Tilli se charge de vous trouver des clients, de la facturation et de vos paiements sécurisés.
          Tout est accessible depuis l'application mobile.`}
        </P>
      </Block>
      <Block title="Bienveillance" icon={heart} inverted>
        <P>Bénéficiez de bons plans, formations exclusives et de petites attentions chaque mois.</P>
      </Block>
      <Block title="Communauté" icon={group}>
        <P>Devenez auto-entrepreneur. N'ayez plus peur de travailler chez vous et partagez vos expériences.</P>
      </Block>
      <Block title="Transparence" icon={euro} inverted>
        <P>Vous n'avez pas à négocier avec votre client, nos prix sont fixés à l'avance.</P>
      </Block>
    </InvertedBlock>
    <ButtonContainer>
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://docs.google.com/forms/d/e/1FAIpQLScvR2ul5h35xj4qXnM0dTLj3cEkaRrmY-7Y9-eZyismNICHog/viewform"
        onClick={trackClick}
      >
        <Button>Devenez couturier</Button>
      </a>
    </ButtonContainer>
  </ContainerBlock>
);

export default TheWhy;
